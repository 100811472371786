.button {
  @apply flex flex-row items-center justify-center cursor-pointer
}

.button-primary {
  @apply bg-brand-b6 hover:bg-brand-b5 border border-transparent active:bg-brand-b7 text-neutral-1;
}

.button-default {
  @apply bg-neutral-1 text-neutral border border-neutral-5 hover:border-neutral hover:text-neutral;
}

.button-primary.button-disabled {
  @apply bg-brand-b6 hover:bg-brand-b5 text-neutral-5 border cursor-not-allowed;
}

.button-default.button-disabled {
  @apply bg-neutral-3 hover:bg-neutral-3 text-third border hover:border-neutral-5 cursor-not-allowed;
}

.button-circle {
  @apply rounded-full p-0;
}

.button-quard {
  @apply rounded-lg;
}
